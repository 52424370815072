.Experiences_Container {
    width: 90%;
    margin: 80px auto;
}

.Experiences_Container .experience {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 70px;
    width: 100%;
    margin: 20px 0;
}

.experience .date span {
    font-size: 0.7em;
    width: max-content;
    display: block;
    color: var(--primaryColor--);
    background-color: var(--secondaryColor--);
    padding: 10px 15px;
    border-top-right-radius: 999px;
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
}

.timelineContainer {
    display: flex;
    gap: 30px;
}

.timeline {
    display: flex;
    justify-content: center;
    position: relative;
}

.dot {
    padding: 5px;
    border-radius: 999px;
    width: 3px;
    height: 3px;
    background: linear-gradient(45deg, rgb(84, 110, 226) 34%, rgba(74, 115, 237, 0.602) 100%);
    position: absolute;
    top: 20px;
    box-shadow: 0px 0px 17px 3px var(--primaryColor--);
}

.experience .line {
    border: 1px solid var(--secondaryColor--);
    min-height: 130px;
    width: 0px;
    border-radius: 999px;
}

.experience .date span i {
    margin-right: 8px;
    font-size: 0.8em;
}

.experienceContent {
    width: 25%;
}

.experience .experienceContent h3 {
    margin-bottom: 10px;
    font-size: 0.8em;
    font-weight: 500;
    color: var(--textColor--);
}
.experience .experienceContent h3 a{
    margin: 0 5px;
    color: var(--primaryColor--);
}
.experience .experienceContent h3 a i{
    font-size: 0.8em;
}

.experience .experienceContent p {
    font-size: 0.7em;
    color: var(--textColor--);
    padding: 10px 0;
}

.experience .experienceContent h2 {
    font-size: 1em;
    color: #2f2f2f;
}

@media screen and (max-width: 900px) {
    .timelineContainer {
        gap: 20px;
    }

    .dot {
        padding: 4px;
        top: 25px;

    }

    .experience .date span {
        font-size: 0.6em;
    }

    .experience .experienceContent h2 {
        font-size: 0.8em;
    }

    .Experiences_Container .experience {
        gap: 30px;
    }

    .experienceContent {
        min-width: 150px;
    }
}