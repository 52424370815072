.button a {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    font-size: 0.8em;
    font-weight: 400;
    color: #fff;
    border: 1px solid transparent;
    background-color: var(--primaryColor--);
    border-radius: 999px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.7s;
}

.button a:hover{
    background-color: var(--hoverColor--);
    color: var(--primaryColor--);
    border: 1px solid var(--primaryColor--);
}

.button a i{
    font-size: 0.9em;
    margin: 7px;
}