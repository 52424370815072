.Details{
    position: fixed;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    background-color: #ffffff59;
    top: 0;
    left: 0;
    z-index: 5;
}
.Details_Container span {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primaryColor--);
    background: var(--hoverColor--);
    border-radius: 999px;
    cursor: pointer;
}
.Details_Container span i{
    font-size: 0.7em;

}
.Details_Container {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    height: 80%;
    max-width: 800px;
    background-color: var(--hoverColor--);
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.Details_Container .Details_Image{
    width: 100%;
    /* height: fit-content; */
}

.Details_Container .Details_Image img{
    width: 100%;
    /* height: 100%; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    object-fit: cover;
}
.Details_Content{
    display: block;
    width: 100%;
    padding: 35px;
    box-sizing: border-box;
    margin: auto 10%;
}

.Details_Content .content{
    margin-bottom: 10px;
    color: var(--textColor--);
}
.Details_Content .content p{
    font-size: 0.8em;
    color: var(--textColor--);
    margin: 8px 0px 25px 0;
    line-height: 20px;
}
.Details_Content .skills{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 35px;
}
.technologies, .tools{
    width: 100%;
}
.Details_Content .skills h3{
    margin-bottom: 10px;
    font-size: 0.9em;
    color: var(--textColor--);
}
.Details_Content .skills ul{
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
}
.Details_Content .skills ul li{
    font-size: 0.8em;
    color: var(--textColor--);
    border-radius: 999px;
    padding: 8px 15px;
    list-style: none;
    background: #fff;
}

@media screen and (max-width: 700px) {
    .Details_Content{
        margin: auto 10px;
    }
}
@media screen and (min-width: 700px) {
    .skills>div {
        flex: 1;
    }
}
