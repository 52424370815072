.Educations_Container{
    width: 90%;
    margin: auto;

}
.Educations_Container .education{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 70px;
    width: 100%;
    margin: 80px 0;
}

.education .date span{
    font-size: 0.8em;
    color: #5f5f5f;
}

.education .date span i{
    margin-right: 8px;
    font-size: 0.8em;
}
.education .education_content h3{
    margin-bottom: 10px;
    font-size: 0.9em;
    font-weight: 500;
    color: #5f5f5f;
}

.education .education_content h2{
    font-size: 0.9em;

}

@media screen and (max-width: 900px) {
    .education .date{
        width: 30%;
    }
    .Educations_Container .education{
        gap: 10px;
    }

}