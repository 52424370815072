.Sidebar{
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--primaryColor--);
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
    opacity: 0;
}

.Sidebar .SidebarContainer{
    padding: 50px;
}

.SidebarContainer ul{
    text-align: center;
    margin: auto;
    height: 80vh;
    display: grid;
    align-content: center;
    gap: 15px;
    font-weight: 800;
    padding: 0;
}
.SidebarContainer ul li{
    list-style: none;
    margin: 2px;
}
.SidebarContainer ul li a{
    text-decoration: none;
    color: #fff;
    letter-spacing: 1px;
    font-size: 1.1em;
    font-family: Gabriela;
    padding: 5px;
    border-bottom: 2px solid transparent;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.SidebarContainer ul li a:hover{
    border-bottom: 2px solid #fff;
}

.ShowSidebar{
    z-index: 7;
    border-radius: 0%;
    opacity: 1;
    backdrop-filter: blur(10px);
    background-color: #00000047;
}