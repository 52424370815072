.Footer{
    width: 100%;
    background-color: var(--primaryColor--);
    margin-top: 50px;
    border-top-left-radius:10px ;
    border-top-right-radius:10px ;
}
.footerContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.footerContainer p{
    font-size: 0.7em;
    color: #fff;
}
