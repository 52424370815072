.About{
    width: 90%;
    margin: 30px auto;
}

.About .AboutContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;
    opacity: 0;
    transform: translateY(70px);
}


.About .AboutContainer .AboutImage{
    width: 50%;
    text-align: right;
}

.About .AboutContainer .AboutImage img{
    max-width: 450px;
    width: 100%;
}

.About .AboutContainer .AboutContent{
    width: 50%;
    padding: 0px 70px 0 20px;
    box-sizing: border-box;
    font-size: 1.1em;
}

.About .AboutContainer .AboutContent p{
    margin: 15px;
    font-size: 0.8em;
    color: #474747;
    font-weight: 400;
    line-height: 20px;
}

@media screen and (max-width: 900px) {
    .About .AboutContainer{
        display: grid;
        justify-items: center;
    }
    .About .AboutContainer .AboutContent{
        text-align: center;
        width: 90%;
        padding: 0;
        margin: 20px 0;
    }

    .About .AboutContainer .AboutImage{
        width: 80%;
        text-align: center;
    }
}