.Contact{
    width: 90%;
    margin: auto;
}

.Contact .contactContainer{
    display: grid;
    gap: 40px;
    align-items: center;
    text-align: center;
}

.contactContent{
    margin: 30px;
}
.contactContainer .contactContent p{
    color: var(--textColor--);
    font-size: 0.8em;
    margin: auto;
}
.contactContainer .contactContent h3{
    padding: 10px;
}
.contactContainer .contactContent h3 a{
    font-size: 0.9em;
    color: var(--primaryColor--);
    text-decoration: none;
}
.contact_options .address i{
    color: var(--textColor--);
    font-size: 0.9em;
}
.contact_options .address h3{
    color: var(--textColor--);
    font-size: 0.8em;
    margin-bottom: 10px;
}
.contactContainer .contact_options .icons ul{
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.contactContainer .contact_options .icons ul li a{
    color: var(--textColor--);
    transition: 0.5s;
}

.contactContainer .contact_options .icons ul li a:hover{
    color: var(--primaryColor--);
}