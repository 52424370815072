.Hero{
    padding: 0 50px;
    height: 100vh;
    margin-top: 100px;
}

.Hero .hero_container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    opacity: 0;
    transform: translateY(70px);
}

.Hero .container{
    text-align: center;
}

.Hero .container h4{
    font-family: "Gabriela", serif;
    font-size: 1.3em;
}

.Hero .container h2{
    font-size: 1em;
}

.Hero .container h1{
    font-size: 5em;
    font-family: "Oleo Script", system-ui;
    margin: 0;
    color: var(--primaryColor--);
}
.Hero .container h1 span{
    font-family: "Gabriela", serif;
    margin-right: 8px;
    font-size: 0.3em;
}
.Hero .hero_image{
    text-align: center;
}
.Hero .hero_image img{
    max-width: 450px;
    width: 100%;
}

.cta{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    gap: 8px;
}

.cta a{
    padding: 10px 15px;
    background: var(--primaryColor--);
    border: 1px solid var(--primaryColor--);
    color: #fff;
    text-decoration: none;
    border-radius: 999px;
    transition: 0.7s;
}

.cta .outline{
    background: transparent;
    color: var(--primaryColor--);
}

.cta a:hover:not(.outline){
    background: var(--primaryColor--);
}
.cta .outline:hover{
    background: var(--hoverColor--);
    /* border: 1px solid var(--hoverColor--); */
}
@media screen and (max-width: 900px) {

    .Hero{
        justify-content: center;
    }

    .Hero .hero_image img{
        width: 80%;
    }
}