.Navbar{
    width: 100%;
    padding: 15px 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--primaryColor--);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: fixed;
    top: 0;
    z-index: 20;
}
.Navbar .logo h1{
    color: #fff;
    font-family: "Oleo Script", system-ui;
    font-size: 1.8em;
    margin: 0;
}
.Navbar i{
    color: #fff;
    font-size: 1.1em;
    cursor: pointer;
}
.Navbar ul{
    display: flex;
    gap: 55px;
    list-style: none;
}

.Navbar ul li a{
    text-decoration: none;
    color: #fff;
    font-weight: 300;
}
.Navbar ul li{
    padding: 5px;
    transition: 0.3s ease;
}

.Navbar ul .active, .Navbar ul li:hover:not(.active){
    border-bottom: 1.5px solid #fff;
}