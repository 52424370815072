@import url('https://fonts.googleapis.com/css2?family=Gabriela&family=Oleo+Script:wght@400;700&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
*{
    font-size: 16px;
    font-family: "Rubik", sans-serif;
    margin: 0;
    padding: 0;
}

:root{
  --primaryColor-- : #5271ff;
  --secondaryColor-- : #dae3ff;
  --hoverColor-- : #e9eefb;
  --textColor--: #494e5f;
}
body{
    background-color: #fafafa;
    letter-spacing: 0.3px;
}
.animate {
    opacity: 1;
    animation: fadeIn 1.7s forwards;
  }
  @keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(70px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
  }
  
::-webkit-scrollbar {
    width: 3px;
    height: 2px;
    transition: 0.7s;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #3e3b8c54;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor--);
    width: 4px;
    height: 8px;
}



.mainCard{
    position: fixed;
    width: 100%;
    height: 50%;
    background-color: #5552ab;
    z-index: 999;
}

.leftCard{
    animation: leftCardfadeOut 3s  cubic-bezier(0.7, 0, 0.2, 1);
}

.rightCard{
    animation: rightCardfadeOut 3s  cubic-bezier(0.7, 0, 0.2, 1);
}

@keyframes leftCardfadeOut {
    from {
        opacity: 1;
        top: 0;
    }
    to {
        opacity: 0;
        top: -50%;
    }
}

@keyframes rightCardfadeOut {
    from {
        opacity: 1;
        bottom: 0;
    }
    to {
        opacity: 0;
        bottom: -50%;
    }
}
