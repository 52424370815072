.Skills{
    width: 100%;
}

.SkillsContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    margin:  50px auto;
    gap: 10px;
    opacity: 0;
    transform: translateY(70px);
}

.SkillsContainer .skillCard{
    background-color: var(--secondaryColor--);
    min-width: 300px;
    flex: 1;
    text-align: center;
    padding: 20px;
    border-radius: 15px;
}

.SkillsContainer .skillCard h1{
    color: var(--textColor--);
    font-size: 1.1em;
}

.SkillsContainer .skillCard ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: 15px 0px;
}

.SkillsContainer .skillCard ul li{
    padding: 10px;
    background-color: white;
    color: var(--textColor--);
    list-style: none;
    border-radius: calc(15px / 2);
    font-size: 0.9em;
    transition: 0.5s;
    display: flex;
    gap: 8px;
    cursor: pointer;
}
/* .SkillsContainer .skillCard ul li:hover{
    color: #e9e8ff;
    background: #7673d8;
} */


@media screen and (max-width: 900px) {

    .SkillsContainer .skillCard{
        width: 100%;
    }

}