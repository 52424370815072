.Projects {
    width: 90%;
    margin: auto;
}

.Projects_Container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    margin: 80px 0;
    opacity: 0;
    transform: translateY(70px);
}

.Projects_Container .project {
    width: 500px;
    border-radius: 10px;
}   

.project .project_image img {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    object-fit: cover;
}

.project .project_content {
    display: grid;
    box-sizing: border-box;
    background-color: transparent;
    width: 100%;
    height: 250px;
    padding: 10px 20px;
    border-radius: 10px;
    align-content: stretch;
}

.showDetail{
    top: 0px !important;
}

.project_content p {
    font-size: 0.8em;
    height: 100px;
    overflow: hidden;
    line-height: 20px;
}

.project_content a {
    padding: 4px;
    cursor: pointer;
    font-size: 0.9em;
    font-weight: 500;
    color: var(--primaryColor--);
}
.project_content a i{
    font-size: 0.9em;
    margin: 7px;
}
@media screen and (max-width: 400px) {
    .project .project_image img {
        height: 200px;
    }
}